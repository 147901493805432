@import '../../scss/mediaQueries';

.previewPage{

    .col-md-6, .col-md-12{
        margin-bottom: 30px;
    }

    .contact-info, .message-info{
        background-color: white;
        padding: 15px;

        span{
            display: block;
        }

        h5{
            margin-top:0;
        }
    }

    .stationery_image{
        width: 100%;
    }

    .handwriting-render{
        padding-bottom: 30px;
    }

    .message-row{
        margin-top:30px;
    }
}
