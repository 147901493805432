@import '../../scss/mediaQueries';
@import '../../scss/colors';

.stationery_image {
    //margin-top: 20px;
    width: 80%;
    box-shadow: 2px 7px 12px 0 rgba(0, 0, 0, 0.1);
}

.stationery_image:hover {
    cursor: pointer;
}

.selectedImage {
    border: solid 10px;
    border-color: $hiltonBlue;
}

// .stationery_image_container img{
//     border: solid 10px;
//     border-color: $hiltonBlue;
// }

.buttonContainer {
    text-align: center;
    margin: 25px 15px 15px;
    @media ($mobile) {
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .send-another {
        background-color: $hiltonBlue;
        color: white;
        text-transform: capitalize;
        margin-top: 20px;
        border-color: $hiltonBlue;
    }


    .flip-button {
        color: $hiltonBlue;
        text-align: center;
        background-color: rgba(175, 175, 175, 0.2);
        border-color: rgba(175, 175, 175, 0.2);
        text-transform: capitalize;
        margin-left: auto;
        margin-right: auto;
        padding-left: 25px;
        padding-right: 25px;
        font-weight: 500;
    }

    .flip-img {
      width: 40px !important;
      height: 22px;
      object-fit: contain;
      vertical-align: middle;
    }

}


/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
}
	/* flip the pane when hovered */
	// .flip-container:hover .flipper {
	// 	transform: rotateY(180deg);
	// }

.flip-container, .front, .back {
	width: 100%;


}

/* flip speed goes here */
.flipper {
	transition: 0.6s;
	transform-style: preserve-3d;
    position: relative;
    &.flipped{
        .front{
            transform: rotateY(180deg);
        }
        .back{
            transform: rotateY(0);
        }
    }
}

/* hide back of pane during swap */
.front, .back {
	backface-visibility: hidden;
    transition: 0.6s;
}


/* front pane, placed above back */
div.front {

	/* for firefox 31 */
    //transform: rotateY(180deg);

    z-index: 2;
    // background: white;

}

/* back, initially hidden pane */
div.back {

	transform: rotateY(-180deg);
    // background: white;
}
