@import 'mixins/pxToEm';
@import 'mixins/pxToRem';

/*colors */
$font-color--dark: #333;
$font-color--light: #fff;
$background--light: #eee;
$background--dark: #222;
$green: #5cb85c;
$red: #d9534f;
$dark-navy: #041935;
$dark-gray: #979797;
$dark-gold : #afa280;
$light-gold: #f5ecd6;
$beige: #fff7ef;
$median-gray: #ccc;
$light-gray: #969696;

/* spacing */
$full-space: 40px;
$half-space: 20px;

/* font sizing */
$font-size--xs: em(10px);
$font-size--sm: em(12px);
$font-size--md: em(16px);
$font-size--lg: em(24px);
$font-size--xl: em(32px);

/* Navigation */


$blue: #01abe8;
$white: #fff;
$grayDark: #333;
$black: #000;
$red: #ff3f58;
