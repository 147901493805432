@import '../../scss/mediaQueries';
@import '../../scss/colors';

.header {
	width: 100%;
	height: auto;
	// display: flex;
	// align-items: stretch;
 	// justify-content: center;
    padding: 20px;

    div.row{
    	> div {
    		height: 100%;
    		display: flex;
    		justify-content: center;
    		align-items: center;

    		h1{
                text-align: center;
                color:$white;
                font-size: 20px;
                font-weight: normal;
                margin: 0;
                padding: 0;
                margin-top: 20px;
            }

            .makepersonalimpression{
                max-height: 65px;
            }

            img{
                max-width: 100%;
            }

            .header_backbutton{
                display: block;
                img{
                    width: 165px;
                    max-width: 100%;
                }
            }
            .sincerely_hilton{

                display: block;
                img{
                    width: 80px;
                    max-width: 100%;
                }
                .title{
                    font-size: 30px;
                    vertical-align: middle;
                    margin-left: 15px;
                }

                a{
                    cursor:pointer;
                    text-decoration: none;
                    color: black;
                }
            }
            .userDiv{
                text-align: right;
            }
            .step{
                text-align: center;
                .dot{
                    height: 15px;
                    width: 15px;
                    margin: 0 20px;
                    background-color: #5a80fb;
                    border: none;
                    border-radius: 50%;
                    display: inline-block;
                    opacity: 0.5;
                }
                .dot.active{
                    opacity: 1;
                }
            }
    	}
    }
}

@media ($tableMin) {
    .header{
        padding: 0;
        height: 100px;
        display: flex;
    	align-items: stretch;
     	justify-content: center;
        div.row{
            width: 100%;
            > div {
                h1{
                    margin-top: 0;
                }
            }
        }
    }
}
