#ConfirmationPage {
	text-align: center;
}

.confirmationPage {
	.buttonContainer{
        .app-btn{
            display: inline-block;
        }
    }
}
