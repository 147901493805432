@import '../../scss/mediaQueries';

.recipientAddressPage{
}

.formRow.autocomplete{
    position: relative;
    overflow: visible;
    ul{
        position: absolute;
        background: #fff;
        top: 42px;
        left: 0;
        right: 0;
        height: auto;
        list-style: none;
        padding: 0;
        overflow: hidden;
        border-radius: 3px;
        max-height: 140px;
        overflow: scroll;
        box-shadow: 0px 0px 10px rgba(0,0,0,.4);
        li{
            cursor: pointer;
            padding: 10px;
            background-color: #fdfdfd;
        	border: solid 0.5px #c2c2c2;
            border-bottom: 0;
            &:last-child{
                border-bottom: solid 0.5px #c2c2c2;
            }
            &[data-empty="true"] {
                cursor: default;
            }
        }
    }
}

a.cant-find-owner{
    color:#999;
    text-align: center;
    cursor: pointer;
    display: block;
}

.contact-owner-relations-modal{
    top: 0;right: 0;bottom: 0;left: 0;
    background: rgba(0,0,0, .5);
    position: fixed;
    display:flex;
    align-items:center;
    justify-content: center;
    z-index: 99;
    .content{
        position: relative;
        width: 90%;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
        padding: 40px;
        text-align: center;
        .cancel{
            background: url('/images/cross.jpg') no-repeat center center;
            width: 20px;
            height: 20px;
            position: absolute;
            top: 15px;
            right: 15px;
        }
        div.heading{
            margin-bottom: 24px;
        }
        p{
            text-align: center;
            margin-bottom: 6px;
            font-size: 20px;
        }
        form{
            height: auto;
            .formRow{
                border-bottom: 0;
            }
            textarea{
                padding: 10px;
                width: 100%;
                min-height: 90px;
                border: none;
                background-color: #fafafa;
            }
            input, textarea{
                &::placeholder{
                    color:#a3a3a3;
                }
            }
        }
        .app-btn{
            margin-top: 24px;
            display: inline-block;
            float: none;
            margin-bottom: 11px;
        }
        span.tip{
            color:#000;
            opacity: .5;
            font-size: 12px;
            text-align: center;
            display: block;
        }
    }
}

@media ($tableMin) {
    .contact-owner-relations-modal{
        .content{
            width: 540px;
        }
    }
}
