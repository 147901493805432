@import '../../scss/mediaQueries';
@import '../../scss/colors';


.compose-message{
    .navigation{
        h2{
            padding: 0 30px;
        }
    }
    .front {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }
    .placeholder-text
    {
        font-size: 20px;

        position: absolute;
        top: 37px;
        left: 16px;

        color: $light-gray;

        text-align: left;
    }
    .disable
    {
        display: none;
    }
    .composeMessage-box{
        position: relative;
        form{
            margin-top: 20px;
            textarea{
                height: 300px;
                font-size: 20px;
                background: transparent;
                border-radius: 3px;
            }
            .charCount{
                position: absolute;
                right: 10px;
                bottom: 10px;
                font-size: 14px;
                color:$black;
                opacity: .5;
                margin: 0;
            }
        }
    }
}

.stationery_textRender
{
    height: 100%;
    transition: opacity .3s ease-in .3s;
    opacity: 1;
    position: absolute;
    width: 100%;
    &.showFront
    {
        transition: opacity 0s ease-in 0s;
        opacity: 0;
    }
}

.handwriting-render
{
    font-size: 55px;
    line-height: 40px;
    text-align: left;
    color: #0040ac;
    word-break: break-word;
    padding-bottom: 150px;

}

.handwriting-style
{
    .handwriting_size
    {
        display: flex;

        justify-content: space-between;
        align-items: center;
        ul.hw-size
        {
            display: flex;
            flex-direction: row;

            margin: 0;
            padding-left: 0;

            list-style: none;
            li
            {
                font-weight: bold;

                display: flex;
                position: relative;

                width: 40px;
                height: 40px;

                color: #46ace6;
                border: 1px solid #ccc;
                cursor: pointer;

                &.active{
                    span{
                        border: 2px solid #46ace6;
                        line-height: 31px;
                    }
                }
                span
                {
                    position: absolute;
                    top: 0;right: 0;bottom: 0;left: 0;
                    line-height: 35px;
                    text-align: center;
                }
                &:nth-child(even)
                {
                    border-right: 0;
                    border-left: 0;
                }
                &:nth-child(1)
                {
                    font-size: 20px;
                }
                &:nth-child(2)
                {
                    font-size: 30px;
                }
                &:nth-child(3)
                {
                    font-size: 40px;
                }
            }
        }
    }
}

.handwriting_list
{
    display: flex;

    margin-top: 20px;
    padding-left: 0;

    list-style-type: none;

    text-align: center;

    flex-wrap: wrap;

    li
    {
        display: flex;

        width: 33.33%;
        margin-bottom: 10px;
        padding: 0 10px;
        align-items: center;
        svg{
            path{
                stroke:$blue;
                stroke-width: 3;
            }
        }
        &.black{
            box-shadow: 0 0 0 5px $hiltonBlack;
            svg{
                path{
                    stroke:$hiltonBlack;
                }
            }
        }
    }

    li:hover
    {
        cursor: pointer;
    }

    img
    {
        width: 300px;
        padding: 0 10px;
    }

    .handwriting-font
    {
        font-size: 30px;
        width: 100%
    }
}

.handwriting-preview
{
    margin-top: 50px;
}

.row.compose
{
    margin-top: 20px;
    .col-sm-7
    {
        .preview
        {
            position: relative;

            margin-right: 0px;
        }
    }
    svg
    {
        pointer-events: none;
    }
}

@media ($tableMin) {
    .row.compose{
        .col-sm-7{
            .preview{
                margin-right: 30px;
            }
        }
    }
}
