$sunsetColor: #f00;
$sunraiseColor: #ffd630;

$sunsetPositionY: 0;
$sunsetPositionX: 340px;
$sunraisePositionY: 0;
$sunraisePositionX: 40px;

/* animation: [animation-name] [animation-duration] [animation-timing-function]
[animation-delay] [animation-iteration-count] [animation-direction]
[animation-fill-mode] [animation-play-state]; */

@keyframes sunrise {
	0% {
		bottom: $sunsetPositionY; /* sun at bottom */
		left: 340px; /* sun at right */
		background: $sunsetColor; /* sun is red */
	}
	33% {
		bottom: 340px; /* sun rises */
		left: 340px;
		background: $sunraiseColor; /* changes color */
	}
	66% {
		bottom: 340px;
		left: $sunraisePositionX; /* sun moves left across sky */
		background: $sunraiseColor; /* maintains its color */
	}
	100% {
		bottom: $sunsetPositionY; /* sun sets */
		left: $sunraisePositionX;
		background: $sunsetColor; /* back to red */
	}
}

@keyframes sky {
	0% {
		background-color: #525252;
	}
	33% {
		background-color: #6293e5;
	}
	66% {
		background-color: #6293e5;
	}
	100% {
		background-color: #525252;
	}
}

@keyframes ground {
	0% {
		background: #6c5228;
	}
	33% {
		background: #48a037;
	}
	66% {
		background: #48a037;
	}
	100% {
		background: #6c5228;
	}
}

@keyframes cloud {
	0% {
		opacity: 0;
		left: -100px;
	}
	50% {
		opacity: 1;
	}
	75% {
		opacity: 1;
		left: 100px;
	}
	100% {
		opacity: 0;
		left: 500px;
	}
}

@keyframes moon {
	0% {
		opacity: 0;
		left: -100px;
	}
	50% {
		opacity: 0;
		left: -100px;
	}
	90% {
		opacity: 0;
		left: -100px;
	}
	100% {
		opacity: 1;
		left: 50px;
	}
}

@mixin basicAnimation($animationName) {
	/* 
	 * CSS Animation Property (Short Hand)
	 * animation: [animation-name] [animation-duration] [animation-timing-function] [animation-delay] [animation-iteration-count] [animation-direction] [animation-fill-mode] [animation-play-state]; 
	 *
	 * CSS Animation Properties example (Individual)
	 * animation-name: sunrise;
	  animation-duration: 10s;
	  animation-timing-function: ease;
	  animation-delay: 0s;
	  animation-iteration-count: 2;
	  animation-direction: normal;
	  animation-play-state: running;
	  animation-fill-mode: forwards
	*/

	animation: $animationName 10s ease 0s 2 normal running forwards;
}

#Animation {}

#sky {
	width: 500px;
	height: 500px;
	margin: 0 auto;
	position: relative;
	overflow: hidden;
	z-index: 1;
	background-color: #525252;

	/* 
	 * Dev Note: 
	 * Animation styles should be grouped in their own 'animate' class/selector.
	 * This allows proper seperation of code and easier control and integration with JavaScript.
	*/
	&.animate {
		@include basicAnimation(sky);//animation: sky 10s ease 0s 2 normal running forwards;
	}
}

#cloud {
	top: 50px;
	left: -100px;
	position: relative;
	opacity: 0;
	z-index: 3;

	.cloud {
		width: 160px;
		height: 50px;
		top: 10px;
		position: absolute;
		background: #fff;
		border-radius: 90px / 30px;

		&.cloud-2 {
			top: 0;	
			left: 50px;
		}
		&.cloud-3 {
			top: 20px;
			left: 110px;
		}
	}

	&.animate {
		@include basicAnimation(cloud);
	}
}

#sun {
	width: 130px;
	height: 130px;
	bottom: 0;
	left: 340px;
	position: absolute;
	z-index: 2;
	background: #ffd630;
	border-radius: 70px;

	&.animate {
		@include basicAnimation(sunrise);
	}
}

#moon {
	top: 50px;
	left: -100px;
	position: relative;
	opacity: 0;

	.moon {
		width: 100px;
		height: 100px;
		border-radius: 50px;
		background: #fff;
		position: absolute;

		&.moon-2 {
			top: -20px;
			left: 20px;
			position: absolute;
			background: #525252;
		}
	}

	&.animate {
		@include basicAnimation(moon);
	}
}

#ground {
	width: 500px;
	height: 154px;
	bottom: 0;
	left: 0;
	position: absolute;
	z-index: 4;
	background: #6c5228;

	&.animate {
		@include basicAnimation(ground);//animation: ground 10s ease 0s 2 normal running forwards;
	}
}


















