@import '../../scss/mediaQueries';
@import '../../scss/colors';

.noteTracker{
    margin-bottom: 40px;
    .actions{
        display:flex;
        align-items: center;
        border-top: 1px solid #d8d8d8;
        padding-top: 30px;
        margin-bottom: 30px;
        .app-btn{
            flex-shrink: 0;
            flex-grow: 1;
        }
    }
    .items{
        width: 100%;
        div.content{
            overflow-x: scroll;
            div.rows{
                width: 700px;
            }
        }
        .item{
            display: flex;
            padding: 10px 0;
            font-size: 12px;
            > div {
                padding-right: 10px;
                width: 10%;
                &.message{
                    width: 36%;
                }
                &.return{
                    width: 14%;
                }
                &.recipient{
                    width: 14%;
                }
                &.status{
                    width: 12%;
                    text-transform: capitalize;
                }
                &.created{
                    width: 14%;
                    text-transform: uppercase;
                }
                &.action{
                    width: 10%;
                    text-align: right;
                    &.editable{
                        a{
                            color:#ff3f58;
                        }
                    }
                    &.not-editable{
                        a{
                            color:#a1a1a1;
                            &:hover{
                                text-decoration: none;
                                cursor: default;
                            }
                        }
                    }
                }
                &:last-of-type{
                    margin-right: 0;
                }
            }
            &:nth-child(even) {
                background: #f6f6f6;
            }
        }
    }
    .cancel-note-modal{
        top: 0;right: 0;bottom: 0;left: 0;
        background: rgba(0,0,0, .5);
        position: fixed;
        display:flex;
        align-items:center;
        justify-content: center;
        z-index: 99;
        .content{
            position: relative;
            width: 90%;
            border-radius: 4px;
            background-color: #ffffff;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
            padding: 40px;
            text-align: center;
            .cancel{
                background: url('/images/cross.jpg') no-repeat center center;
                width: 20px;
                height: 20px;
                position: absolute;
                top: 15px;
                right: 15px;
            }
            div.heading{
                margin-bottom: 24px;
            }
            p{
                text-align: center;
                margin-bottom: 6px;
                font-size: 20px;
            }
            form{
                height: auto;
                .formRow{
                    border-bottom: 0;
                }
                textarea{
                    padding: 10px;
                    width: 100%;
                    min-height: 90px;
                    border: none;
                    background-color: #fafafa;
                }
                input, textarea{
                    &::placeholder{
                        color:#a3a3a3;
                    }
                }
            }
            .app-btn{
                margin-top: 24px;
                display: inline-block;
                float: none;
                margin-bottom: 11px;
            }
            span.tip{
                color:#000;
                opacity: .5;
                font-size: 12px;
                text-align: center;
                display: block;
            }
        }
    }
}

@media ($tableMin) {
    .noteTracker{
        margin-bottom: 60px;
        .cancel-note-modal{
            .content{
                width: 540px;
            }
        }
        .actions{
            display:flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #d8d8d8;
            padding-top: 30px;
            margin-bottom: 30px;
            .app-btn{
                flex-grow:0;
            }
        }
        .items{
            width: auto;
            div.content{
                overflow-x: visible;
                div.rows{
                    width: auto;
                    .item{
                        background: none;
                        > div {
                            &.message{
                                width: 50%;
                            }
                            &.return{
                                width: 12%;
                            }
                            &.recipient{
                                width: 12%;
                            }
                            &.status{
                                width: 10%;
                            }
                            &.created{
                                width: 8%;
                            }
                            &.action{
                                width: 7%;
                            }
                        }
                        &:nth-child(even) {
                            background: #f6f6f6;
                            position: relative;
                            &:before{
                                content: '';
                                position: absolute;
                                top: 0;bottom: 0;left: -100%;
                                width: 100%;
                                background: #f6f6f6;
                            }
                            &:after{
                                content: '';
                                position: absolute;
                                top: 0;bottom: 0;right: -100%;
                                width: 100%;
                                background: #f6f6f6;
                            }
                        }
                    }
                }
            }
        }
    }
}
