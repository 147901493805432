.loginPage {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  height: 630px;
  width: 350px;

  .form-control{
    height: 38px;
  }
  .overlay{
    background-color: $primaryColor;
    position:fixed;
    width:100%;
    height:100%;
    top:0px;
    left:0px;
    z-index:1000;
}

.errors {
  margin-top: 20px;
}

.title {
  font-size: 40px;
  font-weight: 100;
}

.subtitle {
  font-size: 30px;
  font-weight: 900;
  text-transform: none;
}

  a {
      text-decoration: none !important;
  }

  .myform {
      position: relative;
      display: -ms-flexbox;
      display: flex;
      padding: 2rem;
      -ms-flex-direction: column;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      outline: 0;
      max-width: 500px;
      z-index: 2000;
  }
  .tx-tfm {
      text-transform: uppercase;
  }
  .mybtn {
      padding: 10px;
      border-radius: 50px;
      background-color: $primaryColor;
  }

  .mybtn:hover {
    background-color: $primaryColorHover;
}
  .login-or {
      position: relative;
      color: #aaa;
      margin-top: 10px;
      margin-bottom: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .span-or {
      display: block;
      position: absolute;
      left: 50%;
      top: -2px;
      margin-left: -25px;
      background-color: #fff;
      width: 50px;
      text-align: center;
  }
  .hr-or {
      height: 1px;
      margin-top: 0px !important;
      margin-bottom: 0px !important;
  }
  .google {
      color: #666;
      width: 100%;
      height: 40px;
      text-align: center;
      outline: none;
      border: 1px solid lightgrey;
  }
  form .error {
      color: #ff0000;
  }
  #second {
      display: none;
  }
}