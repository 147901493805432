$filePath: '../../fonts/loew';


@font-face {
    font-family: 'loewregular';
    src: url(#{$filePath}/loew-regular-webfont.woff2) format('woff2'),
         url(#{$filePath}/loew-regular-webfont.woff) format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'loewregular';
    src: url(#{$filePath}/loew-light-webfont.woff2) format('woff2'),
         url(#{$filePath}/loew-light-webfont.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}
@font-face {
    font-family: 'loewregular';
    src: url(#{$filePath}/loew-medium-webfont.woff2) format('woff2'),
         url(#{$filePath}/loew-medium-webfont.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'loewregular';
    src: url(#{$filePath}/loew-bold-webfont.woff2) format('woff2'),
         url(#{$filePath}/loew-bold-webfont.woff) format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'loewregular';
    src: url(#{$filePath}/loew-bolditalic-webfont.woff2) format('woff2'),
         url(#{$filePath}/loew-bolditalic-webfont.woff) format('woff');
    font-weight: normal;
    font-style: italic;
}